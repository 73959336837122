<template>
  <div class="card p-shadow-6">
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <h1>Pesquisa</h1>
    <div class="p-field p-grid" style="position: relative">
      <Loading :active.sync="isLoading" :isFullPage="false" style></Loading>
      <div class="p-col-12 p-md-4">
        <div class="p-inputgroup">
          <InputText placeholder="Pesquisar" v-model="toSearch" />
          <Button
            icon="pi pi-search"
            class="p-button-warning"
            v-on:click="startSearch()"
          />
        </div>
        <small
          v-if="toSearch == null || toSearch == '' || toSearch.trim() == ''"
          class="p-error"
          role="alert"
        >
          Insira um termo a pesquisar
        </small>
        <small
          v-if="
            !searchIntervention &&
            !searchInstallations &&
            !searchPats &&
            !searchBookings
          "
          class="p-error"
          role="alert"
        >
          Selecione um item de pesquisa
        </small>
      </div>
      <div class="p-col-4 p-md-1 p-mt-1">
        <span
          class="p-float-label"
          style="top: -0.75rem; left: -1rem; font-size: 12px"
        >
          <label for="private">Intervenções</label>
        </span>
        <InputSwitch
          name="showDetails"
          v-model="searchIntervention"
          class="p-mt-1"
        />
      </div>
      <div class="p-col-4 p-md-1 p-mt-1">
        <span
          class="p-float-label"
          style="top: -0.75rem; left: -1rem; font-size: 12px"
        >
          <label for="private">Reservas</label>
        </span>
        <InputSwitch
          name="showDetails"
          v-model="searchBookings"
          class="p-mt-1"
        />
      </div>
      <div class="p-col-4 p-md-1 p-mt-1">
        <span
          class="p-float-label"
          style="top: -0.75rem; left: -1rem; font-size: 12px"
        >
          <label for="private">Instalações</label>
        </span>
        <InputSwitch
          name="showDetails"
          v-model="searchInstallations"
          class="p-mt-1"
        />
      </div>
      <div class="p-col-4 p-md-1 p-mt-1">
        <span
          class="p-float-label"
          style="top: -0.75rem; left: -1rem; font-size: 12px"
        >
          <label for="private">Pats</label>
        </span>
        <InputSwitch name="showDetails" v-model="searchPats" class="p-mt-1" />
      </div>
      <div class="p-col-4 p-md-1 p-mt-1">
        <span
          class="p-float-label"
          style="top: -0.75rem; left: -1rem; font-size: 12px"
        >
          <label for="private">Propostas</label>
        </span>
        <InputSwitch
          name="showDetails"
          v-model="searchProporsals"
          class="p-mt-1"
        />
      </div>
    </div>
    <div
      v-if="
        searchEnd == true &&
        interventionList.length == 0 &&
        bookingsList.length == 0 &&
        installationsList.length == 0 &&
        patsList.length == 0 &&
        proposalsList.length == 0
      "
    >
      <b>Não Foram encontrados registo com o termo {{ toSearch }}</b>
    </div>
    <DataTable
      v-if="interventionList.length > 0"
      :value="interventionList"
      :filters.sync="filtersIntervention"
      filterDisplay="row"
      :paginator="false"
      class="p-datatable-sm"
      sortField="id"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <span class="">Intervenções</span>
          <span class="p-input-icon-left p-ai-end">
            <i class="pi pi-search" />
            <InputText
              v-model="filtersIntervention['global'].value"
              placeholder="Pesquisar"
            />
          </span>
        </div>
      </template>
      <Column field="pat_number" header="Pat" sortable filterField="pat_number">
        <template #body="slotProps">
          {{ slotProps.data.pat_number }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="project_name"
        header="Projeto"
        sortable
        filterField="project_name"
      >
        <template #body="slotProps">
          {{ slotProps.data.project_name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pat_description"
        header="Descrição"
        sortable
        filterField="pat_description"
      >
        <template #body="slotProps">
          {{ slotProps.data.pat_description }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="pat_problem"
        header="Problema"
        sortable
        filterField="pat_problem"
      >
        <template #body="slotProps">
          {{ slotProps.data.pat_problem }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="technician"
        header="Técnico"
        sortable
        filterField="technician"
      >
        <template #body="slotProps">
          {{ slotProps.data.technician }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="start" header="Inicio" sortable filterField="start">
        <template #body="slotProps">
          {{ slotProps.data.start }}
        </template>
      </Column>
      <Column field="end" header="Fim" sortable filterField="end">
        <template #body="slotProps">
          {{ slotProps.data.end }}
        </template>
      </Column>
      <Column field="note" header="Nota" sortable filterField="note">
        <template #body="slotProps">
          {{ slotProps.data.note }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="resume"
        header="Trabalho efectuado"
        sortable
        filterField="resume"
      >
        <template #body="slotProps">
          {{ slotProps.data.resume }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver informação do Pat'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-primary p-button-outlined p-button-sm"
            @click="viewPatInfo(slotProps.data.pat_number)"
          />
        </template>
      </Column>
    </DataTable>

    <DataTable
      v-if="bookingsList.length > 0"
      :value="bookingsList"
      :filters.sync="filtersBookings"
      filterDisplay="row"
      :paginator="false"
      class="p-datatable-sm p-mt-5"
      sortField="id"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <span class="">Reservas</span>
          <span class="p-input-icon-left p-ai-end">
            <i class="pi pi-search" />
            <InputText
              v-model="filtersBookings['global'].value"
              placeholder="Pesquisar"
            />
          </span>
        </div>
      </template>
      <Column field="date" header="Data" sortable filterField="date">
        <template #body="slotProps">
          {{ slotProps.data.date }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="technician"
        header="Técnico"
        sortable
        filterField="technician"
      >
        <template #body="slotProps">
          {{ slotProps.data.technician }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="start" header="Inicio" sortable filterField="start">
        <template #body="slotProps">
          {{ slotProps.data.start }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="end" header="Fim" sortable filterField="end">
        <template #body="slotProps">
          {{ slotProps.data.end }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="note" header="Notas" sortable filterField="note">
        <template #body="slotProps">
          {{ slotProps.data.note }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="resume"
        header="Trabalho efectuado"
        sortable
        filterField="resume"
      >
        <template #body="slotProps">
          {{ slotProps.data.resume }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="created_by"
        header="Criado por"
        sortable
        filterField="created_by"
      >
        <template #body="slotProps">
          {{ slotProps.data.created_by }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
    </DataTable>

    <DataTable
      v-if="installationsList.length > 0"
      :value="installationsList"
      :filters.sync="filtersInstallations"
      filterDisplay="row"
      :paginator="false"
      class="p-datatable-sm p-mt-5"
      sortField="id"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <span class="">Instalações</span>
          <span class="p-input-icon-left p-ai-end">
            <i class="pi pi-search" />
            <InputText
              v-model="filtersInstallations['global'].value"
              placeholder="Pesquisar"
            />
          </span>
        </div>
      </template>
      <Column
        field="id"
        header="Id"
        sortable
        :headerStyle="{ width: '170px' }"
        filterField="id"
      >
        <template #body="slotProps">
          {{ slotProps.data.id }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="name" header="Nome" sortable filterField="name">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="description"
        header="Descrição"
        sortable
        filterField="description"
      >
        <template #body="slotProps">
          {{ slotProps.data.description }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="seller" header="Vendedor" sortable filterField="seller">
        <template #body="slotProps">
          {{ slotProps.data.seller }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="manager" header="Gestor" sortable filterField="manager">
        <template #body="slotProps">
          {{ slotProps.data.manager }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="responsible_technical"
        header="Responsável"
        sortable
        filterField="responsible_technical"
      >
        <template #body="slotProps">
          {{ slotProps.data.responsible_technical }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column :headerStyle="{ width: '140px' }">
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver instalação'"
            icon="pi pi-eye"
            class="p-button-sm p-button-rounded p-button-secondary p-button-outlined"
            @click="goInstallationsInfo(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>

    <DataTable
      v-if="patsList.length > 0"
      :value="patsList"
      :filters.sync="filtersPats"
      filterDisplay="row"
      :paginator="false"
      class="p-datatable-sm p-mt-5"
      sortField="id"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <span class="">Pats</span>
          <span class="p-input-icon-left p-ai-end">
            <i class="pi pi-search" />
            <InputText
              v-model="filtersPats['global'].value"
              placeholder="Pesquisar"
            />
          </span>
        </div>
      </template>
      <Column field="id" header="Pat" sortable filterMatchMode="contains">
        <template #body="slotProps">
          {{ slotProps.data.id }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="name" header="Nome" sortable filterField="name">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="resume" header="Resumo" filterField="resume">
        <template #body="slotProps">
          {{ slotProps.data.resume }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="problem" header="Problema" filterField="problem">
        <template #body="slotProps">
          {{ slotProps.data.problem }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="date_formated"
        header="Data"
        sortable
        filterField="date_formated"
        dataType="date"
      >
        <template #body="slotProps">
          {{ slotProps.data.date }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column field="solution" header="Solução" sortable filterField="solution">
        <template #body="slotProps">
          {{ slotProps.data.solution }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column header="Data Survey">
        <template #body="slotProps">
          {{ getSurveyDate(slotProps.data) }}
        </template>
      </Column>
      <Column
        field="create_at_formated"
        header="Data Criação"
        sortable
        filterField="create_at_formated"
        dataType="date"
      >
        <template #body="slotProps">
          {{ slotProps.data.create_at }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver informação do Pat'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-primary p-button-outlined p-button-sm"
            @click="viewPatInfo(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>

    <DataTable
      v-if="proposalsList.length > 0"
      :value="proposalsList"
      :filters.sync="filtersProporsals"
      filterDisplay="row"
      :paginator="false"
      class="p-datatable-sm p-mt-5"
      sortField="id"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="flex justify-content-between align-items-center">
          <span class="">Propostas</span>
          <span class="p-input-icon-left p-ai-end">
            <i class="pi pi-search" />
            <InputText
              v-model="filtersProporsals['global'].value"
              placeholder="Pesquisar"
            />
          </span>
        </div>
      </template>
      <Column sortable field="id" header="Id" filterField="id"> </Column>
      <Column
        sortable
        field="client_name"
        header="Cliente"
        filterField="client_name"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column sortable field="subject" header="Assunto" filterField="subject">
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column sortable field="type_name" header="Tipo" filterField="type_name">
        <template #body="slotProps">
          <span
            v-if="slotProps.data.type_id == 1 && slotProps.data.pat_id"
            class="clickable-item"
            v-tooltip="'Ver Survey'"
            @click="viewPatInfo(slotProps.data.pat_id)"
          >
            Survey
          </span>
          <span v-else-if="slotProps.data.type_id == 1"> Survey </span>
          <span v-else-if="slotProps.data.type_id == 2"> Proposta </span>
        </template>
      </Column>
      <Column
        sortable
        field="seller_name"
        header="Vendedor"
        filterField="seller_name"
      >
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        sortable
        field="proposal_assigned_user.username"
        header="R. Orçamento"
        filterField="proposal_assigned_user.username"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.assigned_to != null">{{
            slotProps.data.proposal_assigned_user.username
          }}</span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver Proposta'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-primary p-button-outlined p-button-sm"
            @click="viewProporsal(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import searchService from "../services/search.service";
import { FilterMatchMode } from "primevue/api/";
import { getOnlyDate } from "../helpers/helpers";
export default {
  name: "Search",
  components: {
    Loading: loading,
  },
  data() {
    return {
      isLoading: false,
      toSearch: null,
      searchIntervention: true,
      searchInstallations: true,
      searchPats: true,
      searchBookings: true,
      searchProporsals: true,
      interventionList: [],
      installationsList: [],
      patsList: [],
      bookingsList: [],
      proposalsList: [],
      filtersIntervention: {
        pat_number: { value: null, matchMode: FilterMatchMode.EQUALS },
        project_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pat_description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        pat_problem: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        note: { value: null, matchMode: FilterMatchMode.CONTAINS },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filtersInstallations: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        seller: { value: null, matchMode: FilterMatchMode.CONTAINS },
        manager: { value: null, matchMode: FilterMatchMode.CONTAINS },
        responsible_technical: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      filtersPats: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        problem: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date_formated: { value: null, matchMode: FilterMatchMode.DATE_IS },
        create_at_formated: { value: null, matchMode: FilterMatchMode.DATE_IS },
        solution: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filtersBookings: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        start: { value: null, matchMode: FilterMatchMode.CONTAINS },
        end: { value: null, matchMode: FilterMatchMode.CONTAINS },
        note: { value: null, matchMode: FilterMatchMode.CONTAINS },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        created_by: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filtersProporsals: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.EQUALS },
        seller_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        subject: { value: null, matchMode: FilterMatchMode.CONTAINS },
        client_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        type_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        "proposal_assigned_user.username": {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      searchEnd: false,
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    document.querySelector("#searchInput").value = "";
    this.toSearch =
      this.$route.params.term != undefined ? this.$route.params.term : "";
    this.startSearch();
  },
  methods: {
    viewPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`,
      });
      window.open(route.href, "_blank");
    },
    async startSearch() {
      this.searchEnd = false;
      if (!this.loggedUser.isEsa && !this.loggedUser.isAdmin) {
        return;
      }
      this.interventionList = [];
      this.installationsList = [];
      this.patsList = [];
      this.bookingsList = [];
      this.proposalsList = [];
      if (
        this.toSearch == null ||
        this.toSearch == "" ||
        this.toSearch.trim() == ""
      ) {
        return;
      }
      if (
        !this.searchIntervention &&
        !this.searchInstallations &&
        !this.searchPats &&
        !this.searchBookings &&
        !this.searchProporsals
      ) {
        return;
      }
      this.isLoading = true;
      if (this.searchIntervention) {
        await this.interventionsSearch();
      }
      if (this.searchBookings) {
        await this.bookingsSearch();
      }
      if (this.searchInstallations) {
        await this.installationsSearch();
      }
      if (this.searchPats) {
        await this.patsSearch();
      }
      if (this.searchProporsals) {
        await this.proposalsSearch();
      }
      this.isLoading = false;
      return (this.searchEnd = true);
    },
    interventionsSearch() {
      if (!this.searchIntervention) {
        return;
      }
      return searchService
        .intervention(this.toSearch)
        .then((response) => (this.interventionList = response));
    },
    installationsSearch() {
      if (!this.searchInstallations) {
        return;
      }
      return searchService
        .installations(this.toSearch)
        .then((response) => (this.installationsList = response));
    },
    patsSearch() {
      if (!this.searchPats) {
        return;
      }
      return searchService
        .pats(this.toSearch)
        .then((response) => (this.patsList = response));
    },
    bookingsSearch() {
      if (!this.searchPats) {
        return;
      }
      return searchService
        .bookings(this.toSearch)
        .then((response) => (this.bookingsList = response));
    },
    proposalsSearch() {
      if (!this.searchProporsals) {
        return;
      }
      return searchService
        .proporsals(this.toSearch)
        .then((response) => (this.proposalsList = response));
    },
    getSurveyDate(info) {
      if (!info.firstTask) {
        return;
      }
      if (info.firstTask.pat_service_type != undefined) {
        if (info.firstTask.type == "Survey") {
          return getOnlyDate(new Date(info.firstTask.start));
        } else if (info.firstTask.pat_service_type == "Survey") {
          return getOnlyDate(new Date(info.firstTask.start));
        }
      }
    },
    goInstallationsInfo(id) {
      let route = this.$router.resolve({
        path: `/installation-details/${id}`,
      });
      window.open(route.href, "_blank");
    },
    viewProporsal(id) {
      let route = this.$router.resolve({
        path: `/proposal-detail/${id}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>
